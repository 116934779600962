import styled from "styled-components";

import { xLargeScreenLowerLimit } from "../../shared/breakpoints";
import { GRID_GAP } from "../../shared/constants";

namespace sc {
  export const Container = styled.div`
    padding: 24px 0px 24px 0px;
    justify-content: center;
  `;

  export const Grid = styled.div<{ padding?: string; minHeight?: string }>`
    display: grid;
    min-height: ${(props) => props.minHeight || "520px"};
    position: relative;
    margin: auto;
    grid-template-columns: repeat(auto-fill, 328px);
    grid-auto-rows: 496px;
    grid-gap: ${GRID_GAP}px;
    justify-content: center;
    padding: ${(props) => props.padding || `24px 0px`};
    width: 100%;
    max-width: ${xLargeScreenLowerLimit};
  `;

  export const CustomGrid = styled.div<{
    minHeight?: string;
    padding?: string;
  }>`
    display: grid;
    min-height: ${(props) => props.minHeight || "520px"};
    position: relative;
    grid-template-columns: repeat(auto-fill, 328px);
    grid-auto-rows: minmax(496px, auto);
    grid-gap: ${GRID_GAP}px;
    justify-content: center;
    padding: ${(props) => props.padding || `24px 0px`};
    width: 100%;
    max-width: ${xLargeScreenLowerLimit};
  `;
}

export default sc;
